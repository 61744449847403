import { Routes } from '@angular/router';
import { MembersGuard } from './views/members/members.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadComponent: () => import('./views/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'members',
    loadChildren: () => import('./views/members/members.routes'),
    canActivate: [MembersGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  }
];
